import loader2 from './images/loader2.gif';
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"; //, Navigate
import axios from 'axios'
import './Config';
import './css/animate.min.css';
import './css/style2.css';
import './css/style3.css';
import { useMediaQuery } from "./MediaQuery";
const Login = lazy(()=>import('./Login'));
const Register = lazy(()=>import('./Register'));
const Pages = lazy(()=>import('./Pages'));
const PostAds = lazy(()=>import('./PostAds'));
const Dashboard = lazy(()=>import('./Dashboard'));
const Packages = lazy(()=>import('./Packages'));
const Refferals = lazy(()=>import('./Refferals'));
const DepositHistory = lazy(()=>import('./Deposit_history'));
const WithdrawHistory = lazy(()=>import('./Withdraw_history'));
const UserHistory = lazy(()=>import('./user_history'));
const VideoLink = lazy(()=>import('./VideoLink'));
const EarningHistory = lazy(()=>import('./Earning_history'));
const DepositBalance = lazy(()=>import('./Deposit_balance'));
const WithdrawBalance = lazy(()=>import('./Withdraw_balance'));
const Verify = lazy(()=>import('./Verify'));
const VerifyAdmin = lazy(()=>import('./Verify_admin'));
const ForgotPassword = lazy(()=>import('./Forgot_password'));
const ResetPassword = lazy(()=>import('./Reset_password'));
const Home = lazy(()=>import('./Home'));
const Profile = lazy(()=> import('./Profile'));
const Notifications = lazy(() => import('./Notifications'));
const SingleNotification = lazy(() => import('./SingleNotification'));
const AdminNavbar = lazy(() => import('./admin/Admin_Navbar'));
const AdminSidebar = lazy(() => import('./admin/Admin_Sidebar'));
const AdminDashboard = lazy(()=>import('./admin/Admin_Dashboard'));
const AdminUserHistory = lazy(()=>import('./admin/Admin_UserHistory'));
const AdminPackages = lazy(() => import('./admin/Admin_Packages'));
const AddPackage = lazy(() => import('./admin/AddPackage'));
const AdminVideos = lazy(() => import('./admin/Admin_Videos'));
const AddVideos = lazy(() => import('./admin/AddVideos'));
const AdminTestimonials = lazy(() => import('./admin/Admin_Testimonials'));
const AddTestimonials = lazy(() => import('./admin/AddTestimonials'));
const AdminFaqs = lazy(() => import('./admin/Admin_Faqs'));
const AddFaqs = lazy(() => import('./admin/AddFaqs'));
const AdminTransactions = lazy(() => import('./admin/Admin_Transactions'));
const AddTransactions = lazy(() => import('./admin/AddTransactions'));
const AdminAnnoucements = lazy(() => import('./admin/Admin_Annoucements'));
const AddAnnoucements = lazy(() => import('./admin/AddAnnoucements'));
const AdminSettings = lazy(() => import('./admin/Admin_Settings'));
const AdminPost = lazy(() => import('./admin/Admin_Post'));
const AdminPages = lazy(() => import('./admin/Admin_Pages'));
const AddPages = lazy(() => import('./admin/AddPages'));
const AdminContact = lazy(() => import('./admin/Admin_Contact'));
const AddContact = lazy(() => import('./admin/AddContact'));
const AdminSubscribers = lazy(() => import('./admin/Admin_Subscribers'));
const AddSubscribers = lazy(() => import('./admin/AddSubscribers'));
const AdminRoles = lazy(() => import('./admin/Admin_Roles'));
const AddRoles = lazy(() => import('./admin/AddRoles'));
const AdminAdmins = lazy(() => import('./admin/Admin_Admins'));
const AddAdmins = lazy(() => import('./admin/AddAdmins'));
const AdminSlider = lazy(() => import('./admin/Admin_Slider'));
const AddSlider = lazy(() => import('./admin/AddSlider'));
const AdminCustomPackages = lazy(() => import('./admin/Admin_CustomPackages'));
const AddCustomPackages = lazy(() => import('./admin/AddCustomPackages'));
const AdminRefferals = lazy(() => import('./admin/Admin_Referals'));
const AdminProfile = lazy(() => import('./admin/Admin_Profile'))

function App() {
  const [isLoading, setLoading] = useState(0);
  const [islogin, setLogin] = useState(0);
  const [isOpen, setIsOpen] = useState(false)
  const [isLogin, setIsLogin] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const isMobile = useMediaQuery("(max-width: 550px)");

  const postId = async () => {  
  const token = global.getCookie('token');
  const headers = {
    'Authorization': 'Bearer ' + token
  };
    const user_id = global.getCookie('user_id');
    const res = await axios.post(global.baseurl + 'api/get_parent', {id: user_id }, { headers });
    return res;
  }

  const superAdmin = global.getCookie('is_super_admin');

  useEffect(() => {
    setInterval(()=>{
      const token = global.getCookie('token');
      if(token !== ''){
        setLogin(1);
        setLoading(1);
      }else{
        setLogin(0);
        setLoading(1);
      }
      },500)
    
    
    }, []);

    useEffect(() => {
      const token = global.getCookie('token');
      const headers = {
      'Authorization': 'Bearer ' + token
      };
      const metaTags = document.getElementsByTagName('meta');
      const linkTags = document.getElementsByTagName('link');
  
      const fetchHtmlData = async () => {
        try {
          const response = await axios.get(`${global.baseurl}api/settings-for-website`, { headers });
          for (let i = 0; i < metaTags.length; i++) {
            if (metaTags[i].getAttribute('name') === 'title') {
              metaTags[i].setAttribute('content', response.data.data.title);
            }
            else if (metaTags[i].getAttribute('name') === 'description') {
              metaTags[i].setAttribute('content', response.data.data.description);
            } 
            else if (metaTags[i].getAttribute('name') === 'keywords') {
              metaTags[i].setAttribute('content', response.data.data.keywords);
            } 
          }
          for (let i = 0; i < linkTags.length; i++) {
            if (linkTags[i].getAttribute('rel') === 'icon') {
              linkTags[i].setAttribute('href', `${global.baseurl}images/${response.data.data.favicon}`);
            }
          }
          return response;
        } catch (err) {
          console.log(err);
        }
      }
      fetchHtmlData();
    }, [])
    
    if (isLoading === 0) {
      return null;
    }
    const handleOpen = () => {
      if (isMobile) {
        setIsLink((prevLink) => prevLink ? false: true)
        setIsOpen((prevOpen) => prevOpen ? false: true)
      }
    }

  return (
    
    // <BrowserRouter basename='/demo'>
     <BrowserRouter>   
    <div className='App'>
        
          <Suspense fallback={<div><img src={loader2} width="30%" className='loader2' alt="loader" style={{margin: "auto",display:"block",marginTop:"12%"}} /></div>}>
          
            <Routes>
                <Route path='/' exact element={<Home/>} />
                <Route path='/login' element={<Login />} />
                <Route path='/pages/:page' element={<Pages/>} />
                <Route path='/register' element={<Register/>} />
                <Route path='post_ads' element={<PostAds/>} />
                <Route path='/register/:reffered' element={<Register/>} />
                <Route path='/verify' element={<Verify/>} />
                <Route path='/verify_admin' element={<VerifyAdmin/>} />
                <Route path='/forgot_password' element={<ForgotPassword/>} />
                <Route path='/Reset_password' element={<ResetPassword/>} />

                {Number(islogin) === parseInt(0)  ? (
                  <>
                  <Route path='/dashboard' element={<Navigate replace to="/login" />} />
                  <Route path='/packages' element={<Navigate replace to="/login" />} />
                  <Route path='/refferals' element={<Navigate replace to="/login" />} />
                  <Route path='/deposit_history' element={<Navigate replace to="/login" />} />
                  <Route path='/earning_history' element={<Navigate replace to="/login" />} />
                  <Route path='/withdraw_history' element={<Navigate replace to="/login" />} />
                  <Route path='/deposit_history' element={<Navigate replace to="/login" />} />
                  <Route path='/deposit_balance' element={<Navigate replace to="/login" />} />
                  <Route path='/deposit_balance/:status' element={<Navigate replace to="/login" />} />
                  <Route path='/withdraw_balance' element={<Navigate replace to="/login" />} />
                  <Route path='/video_link' element={<Navigate replace to="/login" />} />
                  {/* <Route path='/notification' element={<Navigate replace to="/login" />} /> */}
                  <Route path='/profile' element={<Navigate replace to="/login" />} />
                  <Route path='admin' element={<Navigate replace to="/login" />} />
                   
                  </>
                ) : ( Number(superAdmin) === parseInt(1) ? (
                  <>
                  <Route path='admin' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="dashboard" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminDashboard isOpen={isOpen} />
                    </>
                    
              } />

                  <Route path='admin/packages' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="packages" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminPackages isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/users_history' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="users_history" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminUserHistory isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/packages/:id/:name' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="packages" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddPackage isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/video_link' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="video_link" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminVideos isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/video_link/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="video_link" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddVideos isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/testimonial' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="testimonial" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminTestimonials isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/testimonial/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="testimonial" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddTestimonials isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/admins' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="admins" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminAdmins isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/admins/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="admins" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddAdmins isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/faqs' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="faqs" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminFaqs isOpen={isOpen} />
                    </>
              } />
                  <Route path='admin/faqs/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="faqs" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddFaqs isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/transactions' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="transactions" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminTransactions isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/transactions/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="transactions" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddTransactions isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/annoucements' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="annoucements" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminAnnoucements isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/annoucements/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="annoucements" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddAnnoucements isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/slider' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="slider" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminSlider isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/slider/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="slider" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddSlider isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/custom_packages' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="custom_packages" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminCustomPackages isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/custom_packages/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="custom_packages" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddCustomPackages isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/pages' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="pages" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminPages isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/pages/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="pages" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddPages isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/contact' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="contact" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminContact isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/contact/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="contact" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddContact isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/subscribers' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="subscribers" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminSubscribers isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/subscribers/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="subscribers" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddSubscribers isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/refferals' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="refferals" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminRefferals isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/roles' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="roles" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminRoles isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/roles/:name/:id' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="roles" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AddRoles isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/settings' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="settings" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminSettings isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/add_post' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="add_post" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminPost isOpen={isOpen} />
                    </>
              } />
              <Route path='admin/profile' element={
                    <>
                  <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
                  <AdminSidebar checkOpen="profile" isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
                  <AdminProfile isOpen={isOpen} />
                    </>
              } />
              
              </>
              //     <Route path='admin' element={
              //       <>
              //     <AdminNavbar setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} setIsLink={setIsLink} />
              //     <AdminSidebar isOpen={isOpen} postId={postId} handleOpen={handleOpen} isLink={isLink} />
              //       </>
              // }>

              //     <Route index element={ <AdminDashboard isOpen={isOpen} />} />
              //     <Route path='packages' element={<AdminPackages isOpen={isOpen} /> } />
              //     <Route path='packages/:name/:id' element={<AddPackage isOpen={isOpen} /> } />
              //     <Route path='video_link' element={<AdminVideos isOpen={isOpen} />} /> 
              //     <Route path='video_link/:name/:id' element={<AddVideos isOpen={isOpen} />} /> 
              //     <Route path='testimonial' element={<AdminTestimonials isOpen={isOpen} />} /> 
              //     <Route path='testimonial/:name/:id' element={<AddTestimonials isOpen={isOpen} />} /> 
              //     <Route path='faqs' element={<AdminFaqs isOpen={isOpen} />} /> 
              //     <Route path='faqs/:name/:id' element={<AddTransactions isOpen={isOpen} />} />
              //     <Route path='transactions' element={<AdminTransactions isOpen={isOpen} />} /> 
              //     <Route path='transactions/:name/:id' element={<AddFaqs isOpen={isOpen} />} />
              //     <Route path='annoucements' element={<AdminAnnoucements isOpen={isOpen} />} /> 
              //     <Route path='annoucements/:name/:id' element={<AddAnnoucements isOpen={isOpen} />} />
              //     <Route path='pages' element={<AdminPages isOpen={isOpen} />} /> 
              //     <Route path='pages/:name/:id' element={<AddPages isOpen={isOpen} />} />
              //     <Route path='settings' element={<AdminSettings isOpen={isOpen} />} /> 
              //     <Route path='add_post' element={<AdminPost isOpen={isOpen} />} /> 
              //     <Route path='contact' element={<AdminContact isOpen={isOpen} />} />
              //     <Route path='contact/:name/:id' element={<AddContact isOpen={isOpen} />} /> 
                  
              //   </Route> 
                  )
                : (
                  <>
                  <Route path='/dashboard' element={<Dashboard setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/packages' element={<Packages setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/refferals' element={<Refferals setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink}/>} />
                  <Route path='/deposit_history' element={<DepositHistory setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/withdraw_history' element={<WithdrawHistory setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/user_history' element={<UserHistory setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/earning_history' element={<EarningHistory setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/deposit_balance' element={<DepositBalance setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/deposit_balance/:status' element={<DepositBalance/>} />
                  <Route path='/withdraw_balance' element={<WithdrawBalance setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/video_link' element={<VideoLink setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/notification' element={<Notifications setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/profile' element={<Profile setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  <Route path='/notification/:id' element={<SingleNotification setIsOpen={setIsOpen} setIsLogin={setIsLogin} isLogin={isLogin} isOpen={isOpen} setIsLink={setIsLink} postId={postId} handleOpen={handleOpen} isLink={isLink} />} />
                  </>
                )
                )}
                
                </Routes>
                  </Suspense>
                  </div>
                  </BrowserRouter>
                  
                  );
                  
                }

                export default App;
                