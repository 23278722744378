import { createGlobalState } from "react-hooks-global-state";
global.baseurl = "https://backend.ourlifechanger.com/public/";//"https://lifechangertesting.cognitiveitsolutions.ca/public/"; //"https://backend.ourlifechanger.com/public/";
//global.localPath = "http://localhost:3000/";
global.localPath = "https://ourlifechanger.com/";
//global.captcha_sitekey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";//6LfubJMlAAAAAIS9rmGEc2X08qYGRnfUojI9nVXh testing
global.captcha_sitekey = "6LfubJMlAAAAAIS9rmGEc2X08qYGRnfUojI9nVXh";
//global.google_client_id = "251460650732-dnl6hri9h96ja119r8kefp539ntjptkj.apps.googleusercontent.com"; //old
global.google_client_id = "30407081049-l28nmbdb0sji0l78oud7kq3jd375lkri.apps.googleusercontent.com";
global.fb_app_id = "1381778642619109";
//global.fb_app_id = "964653934570277";
// //global.token = "111";http://resume.cognitiveitsolutions.ca/public/
// var tokenm='1';http://emp.cognitiveitsolutions.ca/public/


  global.getCookie =(cname)=>{
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i=0; i < ca.length; i++){
        var c = ca[i];
        while(c.charAt(0)===' '){
            c=c.substring(1);
        }
        if(c.indexOf(name)===0){
            return c.substring(name.length,c.length);
        }
    }
    return "";
}


const { setGlobalState, useGlobalState} = createGlobalState({
    token2:'0'
});
let chk = 0;
// function Config() {
//   return (
//     <div>
      
//     </div>
//   )
// }

export {chk,useGlobalState, setGlobalState};
