import { useEffect, useState } from "react";

export const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
          setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
      }, [matches, query]);
    
      return matches;
    
    // const [matches, setMatches] = useState(false);
    
    // useEffect(() => {
    //   const matchQueryList = window.matchMedia(query);
    //   function handleChange(e) {
    //     setMatches(e.matches);
    //   }
    //   matchQueryList.addEventListener("change", handleChange);
    //   return () => {
    //     matchQueryList.removeEventListener("change", handleChange);
    //   };
    // }, [query]);
    // return matches;
    // const [matches, setMatches] = useState(false);

    // useEffect(() => {
    //     const query = `(max-width: ${sizes[screen]})`;
    //     const media = window.matchMedia(query);
    //     if (media.matches !== matches) {
    //         setMatches(media.matches);
    //     }
    //     const listener = () => setMatches(media.matches);
    //     window.addEventListener('resize', listener);
    //     return () => window.removeEventListener('resize', listener);
    // }, [matches, screen]);

    // return matches;
};